var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "Section",
    class: {
      'has-border': _vm.hasBorder
    },
    style: _vm.style
  }, [_c('h3', [_vm._v(_vm._s(_vm.title))]), _vm._t("default")], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }